import React, { useState } from "react";

import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";

import { countryList } from "../../data/country-list";
import { geoUrl } from "../../data/features";

import "../../style/common.css";
import "./mapChart.css";

const MapChart = () => {
  const [hovered, setHovered] = useState<string | undefined>(undefined);
  const [clicked, setClicked] = useState<
    { id: string; name: string; capital: string } | undefined
  >(undefined);

  const [capitalInput, setCapitalInput] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [valid, setValid] = useState<{ [key: string]: boolean }>({});

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(
      clicked?.capital,
      capitalInput,
      clicked?.name,
      countryInput,
      clicked?.capital === capitalInput && clicked.name === countryInput
    );
    if (clicked?.capital === capitalInput && clicked.name === countryInput) {
      setValid({ ...valid, [clicked.id]: true });
    }
  };

  return (
    <div style={{ display: "flex" }} className="h-100">
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
        className="w-100 h-100"
        style={{ border: "1px solid black" }}
      >
        <Sphere
          stroke="#E4E5E6"
          strokeWidth={0.5}
          id={"id"}
          fill="transparent"
        />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {
          <Geographies geography={geoUrl}>
            {({
              geographies,
            }: {
              geographies: { properties: { name: string }; id: string }[];
            }) =>
              geographies.map((geo) => {
                return (
                  <Geography
                    key={geo.properties.name}
                    geography={geo}
                    fill={
                      geo.properties.name === hovered
                        ? "grey"
                        : clicked?.id === geo.id
                        ? "#151d2b"
                        : valid[geo.id]
                        ? "green"
                        : "#F5F4F6"
                    }
                    stroke={"#151d2b"}
                    strokeWidth={0.5}
                    onMouseEnter={() => {
                      if (!valid[geo.properties.name])
                        setHovered(geo.properties.name);
                    }}
                    onMouseLeave={() => {
                      setHovered(undefined);
                    }}
                    onClick={() => {
                      const frenchData = countryList.find(
                        (country: { CODE: string }) => country.CODE === geo.id
                      )!;
                      setClicked({
                        name: frenchData.NOM,
                        id: geo.id,
                        capital: frenchData.CAPITALE,
                      });
                    }}
                  />
                );
              })
            }
          </Geographies>
        }
      </ComposableMap>
      <div className="h-100">
        <form
          onSubmit={onSubmit}
          style={{ display: "flex", flexDirection: "column", margin: "50px" }}
        >
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="m-5"
          >
            <label htmlFor="">Country Name:&nbsp;</label>
            <input
              type="text"
              value={countryInput}
              onChange={(e) => setCountryInput(e.currentTarget.value)}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="m-5"
          >
            <label htmlFor="">Capital City:&nbsp;</label>
            <input
              type="text"
              value={capitalInput}
              onChange={(e) => {
                setCapitalInput(e.currentTarget.value);
              }}
            />
          </div>
          <button className="m-5">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default MapChart;
