import { useGeolocationContext } from "../../context/GeolocationContext";
import { useQuery } from "react-query";
import "./Location.css";
import { useGeolocated } from "react-geolocated";
import { useState } from "react";

function Location() {
  const { getInstructions } = useGeolocationContext();

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const [lastRefresh, setLastRefresh] = useState(new Date().getTime());

  const { isLoading, data } = useQuery(
    "instructions",
    async () => {
      if (!coords?.latitude || !coords?.longitude) {
        return { direction: "N", distance: 0 };
      }
      const instructions = await getInstructions({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
      setLastRefresh(new Date().getTime());
      return instructions;
    },
    { refetchInterval: 30000 }
  );

  const { data: nextRefresh } = useQuery(
    "nextRefresh",
    async () => {
      return 30 - Math.floor((new Date().getTime() - lastRefresh) / 1000);
    },
    { refetchInterval: 1000 }
  );

  return (
    <div className="App">
      <div>Direction: {data?.direction}</div>
      <div>Distance: {data?.distance} mètres</div>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className="italic">
          {"Mise à jour dans "}
          <span>{nextRefresh}</span>
          {" secondes"}
        </div>
      )}
    </div>
  );
}

export default Location;
