import "./TreasureHunt.css";

import FlipCountdown from "@rumess/react-flip-countdown";

function TreasureHunt() {
  return (
    <div className="App">
      <header className="header">
        <h1>Chasse au trésor 2024 🗺 🔎</h1>
      </header>
      <FlipCountdown
        className="countdown"
        theme="light"
        endAt={"2024-08-03T00:00:00.000+01:00"}
        hideYear
        monthTitle="Mois"
        dayTitle="Jours"
        hourTitle="Heures"
        minuteTitle="Minutes"
        secondTitle="Secondes"
      ></FlipCountdown>
    </div>
  );
}

export default TreasureHunt;
