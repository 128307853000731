import React from "react";
import { World } from "./pages/world/World";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  Outlet,
} from "react-router-dom";
import { Home } from "./pages/home/Home";

import "./style/layout.scss";
import "./style/common.css";
import { Capitals } from "./pages/capitals/Capitals";
import { Dodo } from "./pages/dodo/Dodo";

import { useLocation } from "react-router-dom";
import TreasureHunt from "./pages/treasure-hunt/TreasureHunt";
import Location from "./pages/location/Location";
import { GeolocationProvider } from "./context/GeolocationContext";
import { QueryClient, QueryClientProvider } from "react-query";

const Layout = () => {
  const location = useLocation();
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <GeolocationProvider>
        <div className="w-screen h-dvh">
          {(["/world", "/capital"].includes(location.pathname) ||
            process.env.ENV !== "production") &&
            location.pathname !== "/treasure-hunt" && (
              <nav className="flex w-full bg-transparent">
                <div className="w-4 opacity-0"></div>
                <div className="flex bg-white w-full bg-opacity-40">
                  <div className="title">Nicogab</div>
                  <div style={{ display: "flex", margin: "auto" }}>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/capitals">Capitals</NavLink>
                    <NavLink to="/world">World</NavLink>
                  </div>
                </div>
                <div className="w-4 opacity-0"></div>
              </nav>
            )}
          <Outlet />
        </div>
      </GeolocationProvider>
    </QueryClientProvider>
  );
};
/**
 * App is the root React component.
 */
export const App: React.FC<{}> = () => {
  return (
    <div className="w-100 h-100">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                process.env.ENV === "production" ? <Home /> : <TreasureHunt />
              }
            />
            <Route path="world" element={<World />} />
            <Route path="capitals" element={<Capitals />} />
            <Route path="dodo" element={<Dodo />} />
            <Route path="treasure-hunt" element={<TreasureHunt />} />
            <Route path="treasure-hunt/location" element={<Location />} />
            <Route
              path="*"
              element={
                process.env.ENV === "production" ? <Home /> : <TreasureHunt />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
