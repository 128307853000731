import React from "react";
import { FiRefreshCw } from "react-icons/fi";

type FormProps = {
  fields: string[];
  title: string;
  onSubmit: (fieldValues: { [key: string]: string }) => boolean;
  onRefresh?: () => void;
};

export const Form: React.FC<FormProps> = ({
  fields,
  title,
  onSubmit,
  onRefresh,
}: FormProps) => {
  const [fieldValues, setFieldValues] = React.useState<{
    [key in typeof fields[number]]: string;
  }>(Object.fromEntries(fields.map((field) => [field, ""])));

  const resetValues = () => {
    setFieldValues(Object.fromEntries(fields.map((field) => [field, ""])));
  };

  const onSubmitDumb = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const success = onSubmit(fieldValues);
    resetValues();
    if (success) {
      onRefresh && onRefresh();
    }
  };

  return (
    <div
      style={{
        width: "30%",
        maxWidth: "400px",
        backgroundColor: "#f2f2f2",
        padding: "30px",
        margin: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <h1>Question</h1>
        {onRefresh && (
          <button
            onClick={(e) => {
              onRefresh();
              resetValues();
            }}
            style={{ height: "fit-content" }}
          >
            <FiRefreshCw />
          </button>
        )}
      </div>
      <div>
        <h3>{title}</h3>
      </div>
      <form onSubmit={onSubmitDumb}>
        {fields.map((field) => (
          <div
            key={field}
            className={"w-100"}
            style={{
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label htmlFor={field} style={{ padding: "5px 0" }}>
              {field.charAt(0).toUpperCase() + field.slice(1)}:{" "}
            </label>
            <input
              type="text"
              id={field}
              name={field}
              onChange={(e) =>
                setFieldValues({
                  ...fieldValues,
                  [field]: e.currentTarget.value,
                })
              }
              value={fieldValues[field]}
              style={{ width: "100%", margin: "0 0 0 10px" }}
            />
          </div>
        ))}
        <button type="submit" className="common-button">
          Valider
        </button>
      </form>
    </div>
  );
};
