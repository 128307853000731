import cyclingNG from "../../assets/cyclingNG.jpg";

export const Home: React.FC<{}> = () => {
  return (
    <div
      className="w-100 pt-48"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={cyclingNG}
        alt="Nicolas Gabrion"
        style={{ borderRadius: "50%", height: "250px", width: "250px" }}
      />

      <h1>Welcome</h1>
      <div style={{ textAlign: "center" }}>
        <p>I am Nicolas Gabrion, a software engineer from France.</p>
        <p>Welcome to my personal website...Enjoy ! 🤓</p>
      </div>
    </div>
  );
};
