import React from "react";

enum ReportColor {
  SUCCESS = "bg-green-800",
  MISTAKE = "bg-red-800",
  TOTAL = "bg-slate-500",
}

type ReportInfoProps = {
  label: string;
  value: string;
  color: ReportColor;
};

const ReportInfo = ({ label, value, color }: ReportInfoProps) => {
  return (
    <div className="flex flex-row gap-4 justify-between">
      <div>{label}</div>
      <div className={`text-slate-100 w-12 px-2 ${color}	text-center rounded`}>
        {value}
      </div>
    </div>
  );
};

type ReportProps = {
  success: number;
  mistakes: number;
  total: number;
  onReset: () => void;
};

export const Report: React.FC<ReportProps> = ({
  success,
  mistakes,
  total,
  onReset,
}: ReportProps) => {
  return (
    <div
      style={{
        width: "30%",
        maxWidth: "400px",
        backgroundColor: "#f2f2f2",
        padding: "30px",
        margin: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <h1>Resultats</h1>
        {
          <button
            onClick={(e) => {
              onReset();
            }}
            style={{ height: "fit-content" }}
          >
            Reset
          </button>
        }
      </div>
      <div className="flex flex-col gap-1">
        <ReportInfo
          label="Bonnes réponses"
          value={success.toString()}
          color={ReportColor.SUCCESS}
        />
        <ReportInfo
          label="Mauvaises réponses"
          value={mistakes.toString()}
          color={ReportColor.MISTAKE}
        />
        <ReportInfo
          label="Total"
          value={total.toString()}
          color={ReportColor.TOTAL}
        />
      </div>
    </div>
  );
};
