export const Dodo: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>👩🏻‍❤️‍👨🏽 dans 2 dodos ! 🤗</h1>
    </div>
  );
};
