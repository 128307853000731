//empty react componenet displaying hello world

import cyclingNG from "../../assets/geoBg.jpg";

import React from "react";
import "./Capitals.css";
import { countryList } from "../../data/country-list";
import levenshtein from "js-levenshtein";
import { Form } from "../../components/geography/form/Form";
import { ToastContainer, toast } from "react-toastify";
import { Report } from "../../components/geography/report/Report";
import "react-toastify/dist/ReactToastify.css";

type QuestionStatus = "correct" | "wrong" | "empty";

export const Capitals: React.FC<{}> = () => {
  const fields = ["capital"];
  // create useState questionState
  const [questionNumber, setQuestionNumber] = React.useState(
    Math.floor(Math.random() * countryList.length)
  );
  const [correct, setCorrect] = React.useState(0);
  const [mistakes, setMistakes] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const report = Object.fromEntries(
    countryList.map(({ CODE, ...country }) => [
      CODE,
      { status: "empty" as QuestionStatus, ...country },
    ])
  );

  const computeScore = (input: string, expected: string) => {
    //compute relative levenstein distance
    return (
      1 -
      levenshtein(input.toLowerCase(), expected.toLowerCase()) / expected.length
    );
  };

  const submitAnswer = ({
    capital,
  }: {
    [key in typeof fields[number]]: string;
  }) => {
    console.log(capital, questionNumber, countryList[questionNumber]);
    const currentState = report[countryList[questionNumber].CODE];
    if (currentState.status === "empty") {
      setTotal(total + 1);
    }
    if (computeScore(capital, countryList[questionNumber].CAPITALE!) > 0.75) {
      toast("Correct!", { type: "success" });
      setCorrect(correct + 1);
      return true;
    } else {
      toast("Wrong!", { type: "error" });
      setMistakes(mistakes + 1);
      return false;
    }
  };

  const refresh = () => {
    setQuestionNumber(Math.floor(Math.random() * countryList.length));
  };

  return (
    <div
      className="w-100 h-full m-auto flex flex-col justify-center items-center bg-"
      style={{
        backgroundImage: `url(${cyclingNG})`,
        backgroundSize: "cover",
      }}
    >
      <Report
        success={correct}
        mistakes={mistakes}
        total={countryList.length}
        onReset={() => {
          setCorrect(0);
          setMistakes(0);
          setTotal(0);
        }}
      />
      <Form
        fields={fields}
        onSubmit={submitAnswer}
        onRefresh={refresh}
        title={countryList[questionNumber].NOM}
      />
      <ToastContainer />
    </div>
  );
};
